import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import Logotipo from '../../Imagenes/Logotipo.png';
import "../../styles/GlobalPages.css";
import Push from '../../components/servicios/Push';
import { Keyboard } from '@capacitor/keyboard';
import NoticiaModal from '../../components/Home/NoticaModal';
import PubliFija from '../../components/Home/PubliFija';
import { Navbar } from 'reactstrap';
import IonMenuBar from '../../components/Navbar/IonMenuBar';
import { IonButtons, IonMenuButton } from '@ionic/react';
import { isPlatform } from '@ionic/react';
import MenuHomeBtn from '../../components/Home/MenuHomeBtn';
import ConsumoActual from '../../components/Home/ConsumoActual';
import ConfigApp from '../../components/Index/ConfigApp';
import api from '../../components/servicios/api';
import { App } from '@capacitor/app';
import PrimerAccesoPass from '../../components/Clientes/PrimerAccesoPass';
import { UncontrolledAlert } from 'reactstrap';

let tarifa = [];
class HomeTranslated extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    plataforma: true,
    consumo: false,
    botonera: false,
    pass: false
  };


  async componentDidMount() {
    this.setState({ pass: false });
    tarifa = [];
    if (isPlatform('ios')) {
      this.setState({ plataforma: false });
    } else {
      this.setState({ plataforma: true });
    }
    if (isPlatform('android') && !isPlatform('mobileweb') || isPlatform('ios') && !isPlatform('mobileweb')) {
      Push.con();
      Keyboard.setAccessoryBarVisible({ isVisible: true });
    }

    const idioma = JSON.parse(localStorage.getItem("idioma"));
    i18next.changeLanguage(idioma);
    this.forceUpdate();
    App.addListener('backButton', e => {
      if (window.location.pathname === '/' || window.location.pathname === '/Login') {
        App.exitApp();
      } else if (window.location.pathname === '/Home') {
      } else {
        window.history.back()
      }
    })
    let datoApp = await ConfigApp.obtener(idioma);
    if (datoApp == true) {
      const appConf = JSON.parse(localStorage.getItem('appconf'));
      const cliente = JSON.parse(localStorage.getItem('user'));
      const empresa = JSON.parse(localStorage.getItem('empresa'));
      if (empresa.obligar_cambiar_pass == 1 && cliente.pass_cambiada != 1) {
        this.setState({ botonera: false, consumo: false, pass: true });
      } else {
        if (appConf.config_inicio == 1) {

          await api.Clientes.serviciosActivos(cliente.usuario).then(
            ({ data }) => (tarifa = data)
          );
          if (Array.isArray(tarifa)) {
            this.setState({ botonera: false, consumo: true });
          } else {
            this.setState({ botonera: true, consumo: false });
          }
        } else {
          this.setState({ botonera: true, consumo: false });
        }
      }
      if (cliente.cif == process.env.REACT_APP_USUARIO_MAESTRO) {
        this.setState({ accesoMaestro: true });
      }


    } else {
      this.setState({ botonera: true, consumo: false });
    }

  }


  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {!this.state.pass && <div>
          <Navbar fixed="top" className={this.state.plataforma ? 'nav-home' : 'nav-home-ios'}>
            <p className=""></p>
            <IonButtons slot="end" id="main-content" side="end">
              <IonMenuButton slot="end" side="end" className="icon-menu" />
            </IonButtons>
          </Navbar>
          <IonMenuBar />
        </div>}
        <div className="">
          <img className="LogotipoHome"
            src={Logotipo}
            alt="Logotipo" />
        </div>
        {this.state.accesoMaestro &&
          <UncontrolledAlert color="info">
            <span>0 ofertas han cambiado de estado</span>
            <br />
            <span>Tienes 5 cita pendiente</span>

          </UncontrolledAlert>}
        <PubliFija />
        {this.state.consumo && <ConsumoActual />}
        {this.state.botonera && <MenuHomeBtn />}
        {this.state.pass && <div className='obligar_pass'>
          <PrimerAccesoPass />
        </div>}
        <NoticiaModal />
      </React.Fragment >
    );
  }
};

const Home = withTranslation('common')(HomeTranslated)
export default Home;

