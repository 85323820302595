import React from 'react';
import { IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonRouterOutlet } from '@ionic/react';
import { withTranslation } from "react-i18next";
import {
    ticketOutline, barChartOutline, newspaperOutline, serverOutline, readerOutline, peopleCircleOutline, personAddOutline, walletOutline,
    powerOutline, receiptOutline, bookOutline, personCircleOutline, reloadCircleOutline, buildOutline, cashOutline, trendingUpOutline
} from 'ionicons/icons';
import { Link } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class IonMenuBarTranslated extends React.Component {
    state = {
        misDatos: true,
        consumo: true,
        contratos: true,
        recargas: true,
        facturas: true,
        tarifas: true,
        documentos: true,
        noticias: true,
        incidencias: true,
        herramientas: true,
        loading: true,
        agenda: true,
        descuentos: true,
        menuMaestro: false
    };

    componentDidMount() {
        if (localStorage.getItem('user') == undefined) {
            window.location = '/Login';
        }
        const funciones = JSON.parse(localStorage.getItem('funciones'));
        const appConf = JSON.parse(localStorage.getItem('appconf'));
        const cliente = JSON.parse(localStorage.getItem('user'));

        if (cliente.cif == process.env.REACT_APP_USUARIO_MAESTRO) {
            this.setState({ menuMaestro: true });
        }
        if (funciones == null || funciones == '' || funciones == undefined) {


        } else {

            if (funciones.misDatos == 0) {
                this.setState({ misDatos: false });
            }

            if (funciones.consumo == 0) {
                this.setState({ consumo: false });
            }

            if (funciones.contratos == 0) {
                this.setState({ contratos: false });
            }

            if (funciones.recargas == 0) {
                this.setState({ recargas: false });
            }
            if (funciones.facturas == 0) {
                this.setState({ facturas: false });
            }

            if (funciones.tarifas == 0) {
                this.setState({ tarifas: false });
            }

            if (funciones.descuentos == 0) {
                this.setState({ descuentos: false });
            }


            if (funciones.documentos == 0) {
                this.setState({ documentos: false });
            }

            if (funciones.noticias == 0) {
                this.setState({ noticias: false });
            }

            if (funciones.incidencias == 0) {
                this.setState({ incidencias: false });
            }

            if (funciones.herramientas == 0 || appConf.mostrar_puk == 0 || appConf.mostrar_puk == '') {
                this.setState({ herramientas: false });
            }

            if (funciones.agenda == 0) {
                this.setState({ agenda: false });
            }

            if (localStorage.getItem('permisos') !== undefined && localStorage.getItem('permisos') !== '' && localStorage.getItem('permisos') != null) {
                const permisosApp = JSON.parse(localStorage.getItem('permisos'));
                if (!permisosApp.includes(1)) {
                    this.setState({ noticias: false });
                }
                if (!permisosApp.includes(2)) {
                    this.setState({ facturas: false });
                }
                if (!permisosApp.includes(3)) {
                    this.setState({ consumo: false });
                }
                if (!permisosApp.includes(4)) {
                    this.setState({ incidencias: false });
                }
                if (!permisosApp.includes(5)) {
                    this.setState({ contratos: false });
                }
                if (!permisosApp.includes(6)) {
                    this.setState({ documentos: false });
                }
            }
        }
        this.setState({ loading: false });
    }

    render() {
        const { t } = this.props;

        if (this.state.loading) {
            return <PageLoading />;
        }


        return (
            <div className="ion-nav-ios">
                <IonMenu side="end" menuId="first" contentId="main-content" className="ion-bar">
                    <IonHeader>
                        <IonToolbar color="primary">
                            <IonTitle>Menu</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <IonList className="lista-ios">
                            {this.state.misDatos && <IonItem>
                                <Link to='/Datos' className="link-menu-lateral">
                                    <IonIcon className="iconos-menu" icon={personCircleOutline} />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{t('Home.datos',)}</p>
                                    </div>
                                </Link>
                            </IonItem>}
                            {this.state.menuMaestro && <IonItem>
                                <Link to='/Usuarios' className="link-menu-lateral">
                                    <FontAwesomeIcon icon="user-tie" className="icon" />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{/*t('Home.clientes',)*/} Usuarios</p>
                                    </div>
                                </Link>
                            </IonItem>}
                            {this.state.menuMaestro && <IonItem>
                                <Link to='/Clientes' className="link-menu-lateral">
                                    <IonIcon className="iconos-menu" icon={peopleCircleOutline} />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{t('Home.clientes',)}</p>
                                    </div>
                                </Link>
                            </IonItem>}
                            {this.state.menuMaestro && <IonItem>
                                <Link to='/ClientesPotenciales' className="link-menu-lateral">
                                    <IonIcon className="iconos-menu" icon={peopleCircleOutline} />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{t('Home.clientesPot',)}</p>
                                    </div>
                                </Link>
                            </IonItem>}
                            {this.state.menuMaestro && <IonItem>
                                <Link to='/CrearCliente' className="link-menu-lateral">
                                    <IonIcon className="iconos-menu" icon={personAddOutline} />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{/*t('Home.clientesPot',)*/}Crear cliente</p>
                                    </div>
                                </Link>
                            </IonItem>}
                            {this.state.menuMaestro && <IonItem>
                                <Link to='/Ofertas' className="link-menu-lateral">
                                    <IonIcon className="iconos-menu" icon={trendingUpOutline} />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{/*t('Home.clientesPot',)*/}Ofertas</p>
                                    </div>
                                </Link>
                            </IonItem>}
                            {this.state.consumo && <IonItem>
                                <Link to="/Consumo" className="link-menu-lateral">
                                    <IonIcon className="iconos-menu" icon={barChartOutline} />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{t('Home.consumo',)}</p>
                                    </div>
                                </Link>
                            </IonItem>}
                            {this.state.contratos && <IonItem>
                                <Link to="/Contratos" className="link-menu-lateral">
                                    <IonIcon className="iconos-menu" icon={readerOutline} />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{t('Home.contratos',)}</p>
                                    </div>
                                </Link>
                            </IonItem>}
                            {this.state.recargas && <IonItem>
                                <Link to="/Recargas" className="link-menu-lateral">
                                    <IonIcon className="iconos-menu" icon={reloadCircleOutline} />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{t('Recargas.menu',)}</p>
                                    </div>
                                </Link>
                            </IonItem>}
                            {this.state.facturas && <IonItem>
                                <Link to="/Facturas" className="link-menu-lateral">
                                    <IonIcon className="iconos-menu" icon={newspaperOutline} />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{t('Home.facturas',)}</p>
                                    </div>
                                </Link>
                            </IonItem>}
                            {this.state.tarifas && <IonItem>
                                <Link to="/Tarifas" className="link-menu-lateral">
                                    <IonIcon className="iconos-menu" icon={serverOutline} />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{t('Home.tarifas',)}</p>
                                    </div>
                                </Link>
                            </IonItem>}
                            {this.state.tarifas && <IonItem>
                                <Link to={{
                                    pathname: `/Contratacion`,
                                    state: {
                                        operador: 0,
                                        ddi: 0
                                    }
                                }} className="link-menu-lateral">
                                    <IonIcon className="iconos-menu" icon={walletOutline} />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{t('Contratacion.tituloMenu',)}</p>
                                    </div>
                                </Link>
                            </IonItem>}
                            {this.state.descuentos && <IonItem>
                                <Link to="/Descuentos" className="link-menu-lateral">
                                    <IonIcon className="iconos-menu" icon={cashOutline} />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{t('Home.descuentos',)}</p>
                                    </div>
                                </Link>
                            </IonItem>}
                            {this.state.documentos && <IonItem>
                                <Link to="/Documento" className="link-menu-lateral">
                                    <IonIcon className="iconos-menu" icon={receiptOutline} />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{t('Home.documentos',)}</p>
                                    </div>
                                </Link>
                            </IonItem>}
                            {this.state.noticias && <IonItem>
                                <Link to="/Noticias" className="link-menu-lateral">
                                    <IonIcon className="iconos-menu" icon={bookOutline} />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{t('Home.noticias',)}</p>
                                    </div>
                                </Link>
                            </IonItem>}
                            {this.state.incidencias && <IonItem>
                                <Link to="/Tickets" className="link-menu-lateral">
                                    <IonIcon className="iconos-menu" icon={ticketOutline} />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{t('Home.tickets',)}</p>
                                    </div>
                                </Link>
                            </IonItem>}
                            {this.state.herramientas && <IonItem>
                                <Link to="/Herramientas" className="link-menu-lateral">
                                    <IonIcon className="iconos-menu" icon={buildOutline} />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{t('Home.herramientas',)}</p>
                                    </div>
                                </Link>
                            </IonItem>}
                            {this.state.agenda && <IonItem>
                                <Link to="/Agenda" className="link-menu-lateral">
                                    <FontAwesomeIcon icon="address-book" className="iconos-menu" />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{t('Home.agenda',)}</p>
                                    </div>
                                </Link>
                            </IonItem>}
                            <IonItem>
                                <Link to="/Login" className="link-menu-lateral">
                                    <IonIcon className="iconos-menu icon-red-home" icon={powerOutline} />
                                    <div className="menu-lateral-texto">
                                        <p className="menu-lateral">{t('Home.cerrar',)}</p>
                                    </div>
                                </Link>
                            </IonItem>
                        </IonList>
                    </IonContent>
                </IonMenu>
            </div>
        )
    }

}
const IonMenuBar = withTranslation('common')(IonMenuBarTranslated)
export default IonMenuBar;